.footer {
  text-align: center;
  margin: 20px auto;
  font-size: 15px;
}

.btn_footer {
  color: var(--color-gray-300);
  background: none;
  border: none;
  width: 100%;

  &:hover {
    color: var(--color-secondary);
  }
}

.logged_in {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin: 30px 0 20px;
  font-size: 16px;
  opacity: 0.6;
}

.header {
  font-size: 15px;
  margin-bottom: 20px;
}
