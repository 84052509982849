.root {
  padding: 80px 30px;
  min-height: 70vh;

  h1 {
    color: var(--color-secondary);
    border-bottom: 1px solid var(--color-border-100);
    font-size: 19px;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    padding: 12px 20px;
    background: #f7f7f7;
  }

  form {
    padding: 30px;
  }
}

.container {
  max-width: 460px;
  margin: 0 auto;
  border: 1px solid var(--color-border-100);
}
